// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import 'bootstrap';

Rails.start()
ActiveStorage.start()

require("jquery");
import $ from "jquery"
require("jquery.cookie")
window.$ = $;
import { CampaignEditions } from "packs/campaign_editions"
import { DailyEntries } from "packs/daily_entries"

$(document).ready( function(){
  if ($.cookie('hight-contrast') == 'true') {
    $('html').addClass('high-contrast');
  }
  $('#' + $.cookie('toggle-font')).addClass('active');
  $('html').addClass($.cookie('toggle-font'));
  $('.toggle-font').click(function () {
    var id = $(this).attr('id') || ""
    $.cookie('toggle-font', id, { path: '/' });
    $('html').removeClass('toggle-font-0 toggle-font-1 toggle-font-2').addClass(id)
    $('.toggle-font').removeClass('active')
    $(this).addClass('active')
  })

  $('.enable-high-contrast').click(function(){
    $.cookie('hight-contrast', 'true', { path: '/' });
    $('html').addClass('high-contrast');
  });

  $('.disable-high-contrast').click(function(){
    $.cookie('hight-contrast', 'false', { path: '/' });
    $('html').removeClass('high-contrast');
  });

  if ($(".authorization-form #registration #new_user").length) {
    if ($("#user_department_attributes_name").val().length) {
      $("#user_department_id").val("")
    }
    show_hide_custom_department()
    $("#user_department_id").change(show_hide_custom_department)
  }

  CampaignEditions.initialize();
  DailyEntries.initialize();
  DailyEntries.setNavigation();
  DailyEntries.setEntryReviews();

  $(".messageBar").last().delay(3000).fadeOut();

  $(".accept-popup").click(function(){
    var cookie_name = "popup-contract-" + this.dataset.contractId + "-aceepted";
    $.cookie(cookie_name, true);
    $(this).parents(".popup").fadeOut();
  });

  $(".confirm-award-info").click(function(){
    var btn = this;
    $.post( this.dataset.url, function( data ) { }).done(function() {
      $(btn).parents(".popup").fadeOut();
    })
  });

  $(".acknowledge-survey").click(function(){
    var cookie_name = "acknowledge-survey-" + this.dataset.id
    if (this.dataset.required == "true") {
      cookie_name += "-required"
      var e = (1.0 / 24.0);
    } else {
      var e = 365;
    }
    $.cookie(cookie_name, true, {expires: e, path: "/"});
    $(this).parents(".popup").fadeOut();
  });

  $("[data-toggle=tooltip]").tooltip();
})

function show_hide_custom_department() {
  if ($("#user_department_id").val().length) {
    $(".new-department").slideUp()
    $(".new-department input").attr("disabled","disabled")
  } else {
    $(".new-department").slideDown()
    $(".new-department input").removeAttr("disabled")
  }
}
