export const CampaignEditions = {

  initialize: function() {
  if ($("#campaign-edition-details").length) {
    $("#campaign-edition-details #menu-box ul li, .company, .company-name").click(function() {
      $("#campaign-edition-details #details > div").removeClass("active")
      console.log($("#campaign-edition-details #details #"+$(this).data("id")))
      $("#campaign-edition-details #details #"+$(this).data("id")).addClass("active")
    })
  }
  }

}
